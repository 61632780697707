<!--
 * @Description: 属性配置
 * @Author: ChenXueLin
 * @Date: 2021-08-30 13:43:19
 * @LastEditTime: 2022-01-12 17:33:01
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="属性配置"
    :visible="setAttrVisible"
    width="900px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    :before-close="handleClose"
    custom-class="attr-config-dialog"
  >
    <div class="switch-page-button">
      <el-button
        round
        :class="[clickIndex == index ? 'activeButton' : '']"
        v-for="(btn, index) in btnList"
        :key="index"
        @click="switchPage(index)"
        >{{ btn.name }}</el-button
      >
    </div>
    <div class="config-content">
      <el-form label-position="right" label-width="85px">
        <div class="left-content">
          <el-form-item label="电子锁">
            <el-radio-group>
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="门磁">
            <el-radio-group>
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="门磁">
            <el-radio-group>
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="摄像头">
            <el-radio-group>
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="冷机">
            <el-radio-group>
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="液压">
            <el-radio-group>
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="油控">
            <el-radio-group>
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="空调">
            <el-radio-group>
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="中控锁">
            <el-radio-group>
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="断油断电">
            <el-radio-group>
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="尾板控制">
            <el-radio-group>
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="双速度里程表">
            <el-radio-group>
              <el-radio :label="1">码表</el-radio>
              <el-radio :label="2">GPS</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="right-content">
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="电源接入方式"
                class="form-item--1"
                prop="power"
              >
                <e6-vr-select
                  :data="typeList"
                  placeholder="电源接入方式"
                  clearable
                  v-model="value"
                ></e6-vr-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="开关数量"
                class="form-item--1"
                prop="switchnum"
              >
                <!-- :props="{
                    id: 'codeValue',
                    label: 'codeName'
                  }" -->
                <e6-vr-select
                  :data="typeList"
                  placeholder="开关数量"
                  clearable
                  v-model="value"
                ></e6-vr-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                label="设备来源"
                class="form-item--1"
                prop="protocolTypeCode"
              >
                <e6-vr-select
                  :data="typeList"
                  placeholder="设备来源"
                  clearable
                  v-model="value"
                ></e6-vr-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                label="GPS天线位置"
                class="form-item--1"
                prop="antennalocation"
              >
                <e6-vr-select
                  :data="typeList"
                  placeholder="GPS天线位置"
                  clearable
                  v-model="value"
                ></e6-vr-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="主机版本"
                class="form-item--1"
                prop="mainversion"
              >
                <el-input
                  placeholder="主机版本"
                  style="width:178px;"
                  v-model="value"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="LCD版本"
                class="form-item--1"
                prop="lcdversion"
              >
                <el-input
                  placeholder="LCD版本"
                  style="width:178px;"
                  v-model="value"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                label="拍照分辨率"
                class="form-item--1"
                prop="photoResolution"
              >
                <e6-vr-select
                  :data="typeList"
                  placeholder="拍照分辨率"
                  clearable
                  v-model="value"
                ></e6-vr-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                label="传感器应用"
                class="form-item--1"
                prop="photoResolution"
              >
                <e6-vr-select
                  :data="typeList"
                  v-model="value"
                  placeholder="传感器应用"
                  clearable
                ></e6-vr-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                label="备注"
                class="form-item--1"
                prop="mainversion"
              >
                <el-input
                  placeholder="备注"
                  :rows="2"
                  type="textarea"
                  style="width:350px;"
                  v-model="value"
                  maxlength="100"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="门磁" prop="menci">
                <el-checkbox
                  :label="index + 1"
                  v-for="(item, index) in checkboxList1"
                  :key="index"
                  >{{ item.name }}</el-checkbox
                >
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="继电器" prop="menci">
                <el-checkbox
                  :label="index + 1"
                  v-for="(item, index) in checkboxList1"
                  :key="index"
                  >{{ item.name }}</el-checkbox
                >
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="湿度">
                <el-checkbox-group v-model="cameraChannelList">
                  <el-checkbox
                    :label="index + 1"
                    v-for="(item, index) in checkboxList2"
                    :key="index"
                    >{{ item.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="温控">
                <el-checkbox-group v-model="cameraChannelList">
                  <el-checkbox
                    :label="index + 1"
                    v-for="(item, index) in checkboxList2"
                    :key="index"
                    >{{ item.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="摄像头">
                <el-checkbox-group v-model="cameraChannelList">
                  <el-checkbox
                    :label="index + 1"
                    v-for="(item, index) in checkboxList2"
                    :key="index"
                    >{{ item.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getCombination } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "attributeConfig",
  components: {},
  data() {
    return {
      mcChannelList: [],
      cameraChannelList: [],
      value: "",
      checkboxList1: [
        {
          name: "一路",
          label: 1
        },
        {
          name: "二路",
          label: 2
        },
        {
          name: "三路",
          label: 3
        },
        {
          name: "四路",
          label: 4
        }
      ],
      checkboxList2: [
        {
          name: "一路",
          label: 1
        },
        {
          name: "二路",
          label: 2
        },
        {
          name: "三路",
          label: 3
        },
        {
          name: "四路",
          label: 4
        },
        {
          name: "五路",
          label: 5
        },
        {
          name: "六路",
          label: 6
        },
        {
          name: "七路",
          label: 7
        },
        {
          name: "八路",
          label: 8
        },
        {
          name: "九路",
          label: 9
        },
        {
          name: "十路",
          label: 10
        },
        {
          name: "十一路",
          label: 11
        },
        {
          name: "十二路",
          label: 12
        },
        {
          name: "十三路",
          label: 13
        },
        {
          name: "十四路",
          label: 14
        },
        {
          name: "十五路",
          label: 15
        },
        {
          name: "十六路",
          label: 16
        }
      ],
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ]
    };
  },
  props: {
    setAttrVisible: {
      type: Boolean,
      default: false
    }
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    setAttrVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getCombinationReq();
        }
      }
    }
  },
  methods: {
    //获取所有组合
    async getCombinationReq() {
      try {
        let res = await getCombination({
          taskItemId: this.basicInfo.itemId
        });
        console.log(res.data, "----");
        // if (res.code == "OK") {
        //   let feeDetailData = res.data.feeInfoVOList;
        //   feeDetailData.map(item => {
        //     item.chargeMoney = item.recMoney;
        //   });
        //   this.feeDetailData = feeDetailData;
        // }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleClose() {
      this.$emit("handleClose", "setAttrVisible");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
.attr-config-dialog {
  .config-content {
    /deep/ .el-form {
      display: flex;
    }
    .left-content {
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 20px;
      box-sizing: border-box;
      width: 260px;
      border-right: 1px solid #efefef;
      /deep/ .el-form-item--mini.el-form-item,
      .el-form-item--small.el-form-item {
        margin-bottom: 5px;
      }
    }
    .right-content {
      width: calc(100% - 260px);
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 15px;
      box-sizing: border-box;
    }
  }
}
</style>
